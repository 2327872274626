var exports = {};
var L = Object.defineProperty;

var J = t => L(t, "__esModule", {
  value: !0
});

var K = (t, e) => {
  J(t);

  for (var n in e) L(t, n, {
    get: e[n],
    enumerable: !0
  });
};

K(exports, {
  default: () => B,
  defaultOptions: () => y
});

var U = t => t && typeof t == "object" && !Array.isArray(t) && !(t instanceof NodeList) && !(t instanceof Node) && !(t.el instanceof Node);

function X(t, e) {
  return e && t && Object.keys(t).forEach(n => {
    let o = t[n];

    if (n === "class" || n === "className") {
      let r = o && o.trim().split(/[\s]+/);
      r && r.length && e.classList.add(...r);
    } else n === "innerHTML" || n === "textContent" || typeof o == "function" || n.startsWith("$") ? e[n] = o : o !== !1 && o !== void 0 && o !== null && e.setAttribute(n, o);
  }), e;
}

function H(t, e) {
  if (!!t) {
    if (!t.forEach) {
      e.append(t);
      return;
    }

    if (t.forEach) {
      t.forEach(n => H(n, e));
      return;
    }
  }
}

function p(t, ...e) {
  let [n, ...o] = t.split("."),
      r = document.createElement(n || "div"),
      a = e[0],
      i = U(a) && a;
  return o.length && (r.className = o.join(" ")), X(i, r), H(i ? e.slice(1) : e, r), r;
}

function d(t, e, n, o) {
  return t.addEventListener(e, n, o), () => t.removeEventListener(e, n, o);
}

var T = t => e => {
  let n = t > 0 ? "nextElementSibling" : "previousElementSibling",
      o = e.target[n];

  for (let r = 0; o && r < Math.abs(t) - 1; ++r) o = o[n];

  o && o.focus();
};

function f(t, e) {
  let n = {
    ArrowLeft: T(-1),
    ArrowUp: T(-t),
    ArrowRight: T(1),
    ArrowDown: T(t)
  };
  return o => {
    let r = e && e[o.code] || n[o.code];
    r && (o.preventDefault(), r(o));
  };
}

function m(t, e, n) {
  return p("button", {
    tabindex: -1,
    type: "button",
    ...e,
    className: t
  }, n);
}

var _ = t => `00${t}`.slice(-2),
    O = (t, e) => {
  let n = e.timeFormat === 12;
  return `${t.getHours() % (n ? 12 : 24) || (n ? 12 : 0)}`;
},
    S = t => _(t.getMinutes()),
    A = t => t.target.select();

function Y(t, e) {
  let n = e.timeFormat === 24 ? "" : t.getHours() >= 12 ? " PM" : " AM";
  return O(t, e) + ":" + S(t) + n;
}

function F(t, e) {
  let n = e.getHours(),
      o = O(e, t),
      r = S(e),
      a = n >= 12 ? "pm" : "am";
  return {
    hh: o,
    mm: r,
    ampm: a
  };
}

function z(t, e) {
  let n = parseInt(t.hh || "0", 10);
  return e ? n % 12 + (t.ampm === "pm" ? 12 : 0) : n;
}

function N(t) {
  let {
    opts: e
  } = t,
      {
    lang: n
  } = e,
      o = e.timeFormat === 12,
      r = F(e, t.selectedDate || t.currentDate),
      a = () => {
    let l = new Date(t.currentDate);
    l.setHours(z(r, o), parseInt(r.mm || "0", 10)), t.goto(l);
  },
      i = l => {
    l.code === "Enter" && (l.preventDefault(), l.target.onchange(l), t.apply());
  },
      c = p("input.dp-txt-time.dp-txt-hh", {
    type: "text",
    placeholder: "hh",
    maxlength: 2,
    onfocus: A,
    onkeydown: i,

    onchange(l) {
      r.hh = l.target.value, a();
    },

    value: r.hh
  }),
      u = p("input.dp-txt-time.dp-txt-mm", {
    placeholder: "mm",
    type: "text",
    maxlength: 2,
    onfocus: A,
    onkeydown: i,

    onchange(l) {
      r.mm = l.target.value, a();
    },

    value: r.mm
  }),
      s = m("dp-ampm", {
    tabindex: "auto",

    onclick(l) {
      r.ampm = r.ampm === "pm" ? "am" : "pm", l.target.textContent = r.ampm, a();
    }

  }, r.ampm),
      h = p("footer.dp-time-footer", c, ":", u, o ? s : "", n.applyText && m("dp-apply", {
    tabindex: "auto",

    onclick() {
      t.apply();
    }

  }, n.applyText));

  return d(t.root, "selecteddatechange", () => {
    t.selectedDate && (Object.assign(r, F(t.opts, t.selectedDate)), c.value = r.hh, u.value = r.mm, s.textContent = r.ampm);
  }), h;
}

function y() {
  let t = {
    lang: {
      applyText: "Apply",
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    },
    timeFormat: 12,
    highlightedDate: new Date(),

    format(e) {
      return e.getMonth() + 1 + "/" + e.getDate() + "/" + e.getFullYear() + (t.pickTime ? " " + Y(e, t) : "");
    },

    parse(e) {
      let n = new Date(e);
      return isNaN(n) ? void 0 : n;
    },

    dateClass() {
      return "";
    },

    inRange(e) {
      let {
        min: n,
        max: o
      } = t;
      return (!n || e >= n) && (!o || e <= o);
    },

    appendTo: document.body,
    dayOffset: 0
  };
  return t;
}

function D() {
  var t = new Date();
  return t.setHours(0, 0, 0, 0), t;
}

function x(t, e) {
  return (t && t.toDateString()) === (e && e.toDateString());
}

function g(t, e) {
  return t = new Date(t), t.setDate(t.getDate() + e), t;
}

function v(t, e, n) {
  t = new Date(t);
  var o = t.getDate(),
      r = t.getMonth() + e;
  return t.setDate(1), t.setMonth(n ? (12 + r) % 12 : r), t.setDate(o), t.getDate() < o && t.setDate(0), t;
}

function w(t, e) {
  return t = new Date(t), t.setFullYear(t.getFullYear() + e), t;
}

function C(t, e) {
  return t = new Date(t), t.setFullYear(e), t;
}

function j(t, e) {
  return v(t, e - t.getMonth());
}

function E(t, e, n) {
  return t && (e && t < e ? e : n && t > n ? n : t);
}

function $(t) {
  let {
    opts: e,
    currentDate: n
  } = t,
      {
    months: o
  } = e.lang,
      r = n.getMonth(),
      a = p(".dp-months.dp-submenu", {
    onclick: c => c.stopPropagation(),
    onkeydown: f(3)
  }, o.map((c, u) => m(`dp-month${r === u ? " dp-selected" : ""}`, {
    onclick: () => t.goto(j(n, u))
  }, c)));
  t.submenu(a);
  let i = a.querySelector(".dp-selected");
  i && i.focus();
}

function G(t) {
  let e = [],
      {
    max: n,
    min: o
  } = t.opts,
      r = (n ? new Date(n) : w(D(), 25)).getFullYear(),
      a = (o ? new Date(o) : w(D(), -25)).getFullYear();

  for (let i = a; i <= r; ++i) e.push(i);

  return e;
}

function I(t) {
  let {
    currentDate: e,
    selectedDate: n
  } = t,
      o = e.getFullYear(),
      r = n?.getFullYear(),
      a = p(".dp-years.dp-submenu", {
    onclick: c => c.stopPropagation(),
    onkeydown: f(1)
  }, G(t).map(c => m(`dp-year${o === c ? " dp-selected" : ""}${r === c ? " dp-selected" : ""}`, {
    onclick: () => t.goto(C(e, c))
  }, c)));
  t.submenu(a);
  let i = a.querySelector(".dp-selected");
  i && i.focus();
}

function Q(t, e) {
  let n = [],
      o = new Date(t);
  o.setDate(1), o.setDate(1 - o.getDay() + e), e && o.getDate() === e + 1 && o.setDate(e - 6);

  for (let r = 0; r < 6 * 7; ++r) n.push(new Date(o)), o.setDate(o.getDate() + 1);

  return n;
}

function M(t, e) {
  let {
    opts: n,
    currentDate: o,
    selectedDate: r
  } = t,
      {
    days: a,
    months: i
  } = n.lang,
      c = o.getMonth(),
      u = D();
  return p(`.dp-body.${e}`, p("header.dp-cal-header", m("dp-cal-month", {
    onclick: s => {
      s.stopPropagation(), $(t);
    }
  }, i[c]), m("dp-cal-year", {
    onclick: s => {
      s.stopPropagation(), I(t);
    }
  }, o.getFullYear())), p(".dp-days", {
    onkeydown: f(7, {
      ArrowLeft(s) {
        t.goto(g(new Date(parseInt(s.target.dataset.date)), -1));
      },

      ArrowUp(s) {
        t.goto(g(new Date(parseInt(s.target.dataset.date)), -7));
      },

      ArrowRight(s) {
        t.goto(g(new Date(parseInt(s.target.dataset.date)), 1));
      },

      ArrowDown(s) {
        t.goto(g(new Date(parseInt(s.target.dataset.date)), 7));
      }

    })
  }, a.map((s, h) => p("span.dp-col-header", a[(h + n.dayOffset) % a.length])), Q(o, n.dayOffset).map(s => {
    let h = s.getMonth() !== c,
        l = !n.inRange(s),
        b = x(s, u),
        k = x(s, o),
        R = x(s, r),
        W = "dp-day" + (h ? " dp-edge-day" : "") + (k ? " dp-current" : "") + (R ? " dp-selected" : "") + (l ? " dp-day-disabled" : "") + (b ? " dp-day-today" : "") + n.dateClass(s);
    return m(W, {
      disabled: l,

      onclick() {
        n.pickTime ? t.setSelectedDate(s) : t.apply(s);
      },

      tabindex: k ? "" : -1,
      "data-date": s.getTime()
    }, s.getDate());
  })));
}

function V(t) {
  return p(".dp-cal", {
    tabindex: -1
  }, p(".dp-cal-header-placeholder"), m("dp-prev", {
    onclick: () => t.goto(v(t.currentDate, -1)),
    "aria-label": "Prev"
  }, "\u2039"), m("dp-next", {
    onclick: () => t.goto(v(t.currentDate, 1)),
    "aria-label": "Next"
  }, "\u203A"), M(t, ""));
}

var P = class {
  constructor(e) {
    this.opts = e, this.currentDate = e.highlightedDate, this.root = V(this), e.pickTime && this.root.append(N(this)), d(this.root, "click", () => this.submenu());
  }

  redraw(e) {
    this.root.querySelector(".dp-body").replaceWith(e), this.submenu();
  }

  setOpts(e) {
    Object.assign(this.opts, e), this.selectedDate = E(this.selectedDate, e.min, e.max), this.currentDate = E(this.currentDate, e.min, e.max), this.redraw(M(this, ""));
  }

  submenu(e) {
    let n = this.root.querySelectorAll(".dp-submenu");
    !e && !n.length || (n.forEach(o => o.remove()), e ? this.root.appendChild(e) : this.root.querySelector(".dp-current").focus());
  }

  goto(e) {
    let n = this.currentDate !== e,
        o = this.currentDate < e,
        r = this.currentDate.getMonth() === e.getMonth() && this.currentDate.getFullYear() === e.getFullYear();
    this.currentDate = e, this.redraw(M(this, r ? "" : o ? "dp-body-next" : "dp-body-prev")), n && this.root.dispatchEvent(new CustomEvent("currentdatechange", {
      detail: e
    })), setTimeout(() => {
      this.root.contains(document.activeElement) || this.root.querySelector(".dp-current").focus();
    });
  }

  setSelectedDate(e) {
    this.selectedDate = E(e || this.currentDate, this.opts.min, this.opts.max), this.goto(this.selectedDate), this.root.dispatchEvent(new CustomEvent("selecteddatechange", {
      detail: this.selectedDate
    }));
  }

  apply(e) {
    this.setSelectedDate(e), this.root.dispatchEvent(new CustomEvent("apply", {
      detail: this
    }));
  }

};

function q(t, e) {
  let {
    root: n,
    opts: o
  } = t,
      r = [],
      a = () => {
    r.forEach(u => u()), r = [], n.remove();
  },
      i = () => p(".tab-catcher", {
    tabindex: "0",

    onfocus() {
      e.focus(), a();
    }

  }),
      c = () => {
    n.isConnected || (t.setSelectedDate(t.opts.parse(e.value)), n.classList.add("dp-flyout"), o.appendTo.append(n), Z(e, t), n.querySelector(".dp-current").focus(), r = [d(document.body, "focus", u => {
      u.target !== document.body && u.target !== e && !n.contains(u.target) && a();
    }, !0), d(document.body, "mousedown", u => {
      u.target !== e && !n.contains(u.target) && a();
    })]);
  };

  n.prepend(i()), n.append(i()), d(e, "click", c), d(e, "focus", c), d(n, "apply", () => {
    e.value = t.selectedDate ? o.format(t.selectedDate) : "", e.focus(), a();
  });
}

function Z(t, e) {
  let n = 8,
      o = document.documentElement,
      r = e.root,
      a = window.scrollX,
      i = window.scrollY,
      c = t.getBoundingClientRect(),
      u = r.getBoundingClientRect(),
      s = c.left + a,
      h = c.bottom + i + n,
      l = {
    left: `${s}px`
  },
      b = {
    top: `${h + n}px`
  };
  h + u.height > o.clientHeight + i && (b = {
    top: `${Math.max(n, i + c.top - n - u.height)}px`
  }), s + u.width > o.clientWidth + a && (l = {
    left: `${Math.max(n, a + o.clientWidth - u.width - n)}px`
  }), Object.assign(r.style, b, l), e.root.style.visibility = "";
}

function B(t) {
  let e = Object.assign(y(), t);
  e.lang = Object.assign(y().lang, t?.lang);
  let n = new P(e);
  return e.input && q(n, e.input), n;
}

export default exports;